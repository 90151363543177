<template>
    <div class="mt-5 px-xl-5">
        <b-row class="justify-content-center">
            <b-col lg="8" xl="8">
                <h6 class="page-title pb-2 text-uppercase">
                    {{ $t("send-official-invitation.title") }}
                </h6>
            </b-col>
        </b-row>
        <b-row class="mb-5 justify-content-center">
            <b-col lg="8" xl="8">
                <b-card
                    class="shadow-sm border-0 rounded-0 px-md-4"
                    bg-variant="light"
                    no-header
                >
                    <b-card-text>
                        <b-row class="mt-2">
                            <b-col md="6">
                                <b-form-group>
                                    <label
                                        class="form-title"
                                        label-for="name-input"
                                    >
                                        {{ $t("general.email") }}
                                    </label>
                                    <b-form-input
                                        v-model="invitationForm.email"
                                        size="sm"
                                        required
                                        class="rounded-0"
                                    ></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group v-if="showCompetentAuthorities">
                                    <label class="form-title">
                                        {{ $t("general.competent-authority") }}
                                    </label>
                                    <select
                                        class="
                                            form-select form-select-sm
                                            rounded-0
                                        "
                                        v-model="competentAuthorityId"
                                    >
                                        <option
                                            v-for="competentAuthority in competentAuthorities"
                                            :key="competentAuthority.id"
                                            :value="competentAuthority.id"
                                        >
                                            {{ competentAuthority.name }}
                                        </option>
                                    </select>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2 mt-4">
                            <b-col class="text-end">
                                <b-overlay
                                    :show="sending"
                                    rounded
                                    opacity="0.7"
                                    spinner-small
                                    spinner-variant="primary"
                                    class="d-inline-block"
                                >
                                    <b-button
                                        class="main-btn rounded px-4"
                                        :disabled="!canSend"
                                        @click="sendInvitation"
                                        >Enviar</b-button
                                    >
                                </b-overlay>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { RepositoryFactory } from "@/services/api";
import { mapGetters, mapActions } from "vuex";

export default {
    name: "send-official-invitation",
    data() {
        return {
            sending: false,
            invitationForm: {
                email: "",
            },
            competentAuthorityId: null,
            competentAuthorities: [],
        };
    },
    async mounted() {
        if (!this.allowRoles) {
            this.$router.push({
                name: "officials",
            });
        }
        if (this.showCompetentAuthorities) {
            await this.loadCompetentAuthorities();
        }
    },
    computed: {
        ...mapGetters("application", [
            "isSuperintendence",
            "allowSuperintendenceCreateCA",
            "allowOfficialCreateAndAsignRoles",
        ]),
        allowRoles() {
            return (
                this.allowSuperintendenceCreateCA ||
                this.allowOfficialCreateAndAsignRoles
            );
        },
        showCompetentAuthorities() {
            return this.isSuperintendence;
        },
        canSend() {
            let form = this.invitationForm;
            let validData = form.email !== "";
            if (this.showCompetentAuthorities) {
                return validData && this.competentAuthorityId !== null;
            }
            return validData;
        },
    },
    methods: {
        ...mapActions("modals", ["openModal", "setModalInfo"]),
        async loadCompetentAuthorities() {
            try {
                var response =
                    await RepositoryFactory.competentAuthority.getAll();
                this.competentAuthorities = response.data;
            } catch (error) {
                window.console.log(error);
            }
        },
        async sendInvitation() {
            this.sending = true;
            try {
                let payload = {
                    officialInvitation: {
                        email: this.invitationForm.email,
                        competentAuthorityId: this.competentAuthorityId,
                    },
                };
                await RepositoryFactory.competentAuthority.sendInvitation(
                    payload
                );
                this.setSuccesInfo();
            } catch (error) {
                window.console.log(error);
                this.setErrorInfo();
            }
            this.openModal();
            this.sending = false;
        },
        setSuccesInfo() {
            this.setModalInfo({
                title: this.$t("send-official-invitation.success.title"),
                message: this.$t("send-official-invitation.success.message"),
                routeName: "officials",
            });
        },
        setErrorInfo() {
            this.setModalInfo({
                title: this.$t("send-official-invitation.error.title"),
                message: this.$t("send-official-invitation.error.message"),
                subMessage: this.$t(
                    "send-official-invitation.error.subMessage"
                ),
            });
        },
    },
};
</script>